import React, { useState } from "react"
import { Link } from "gatsby"
import { Container, Row, Col, Form, Button } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import "./PeopleVideoList.scss"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  fadeInFromRight,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"
import PlayVideo from "../../PlayVideo/PlayVideo"
const PeopleVideoList = props => {
  const [isPlay, setIsPlay] = useState(false)
  const [isIndex, setIndex] = useState(false)
  const playVideoHandler = () => {
    setIsPlay(true)
  }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="peoplevideolist-form"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <div className="our-people-component">
                <div className="form-content">
                  {props.Title && (
                    <motion.div variants={fadeInFromLeft}>
                      <ReactMarkdown source={props.Title} allowDangerousHtml />
                    </motion.div>
                  )}
                  {props.Content && (
                    <motion.div variants={fadeInFromLeft}>
                      <ReactMarkdown
                        source={props.Content}
                        allowDangerousHtml
                      />
                    </motion.div>
                  )}
                </div>

                <div className="right-video-module">
                  <picture className="thumbnail">
                    {props.Video_Url && (
                      <button
                        type="button"
                        className="play-btn"
                        onClick={playVideoHandler}
                      >
                        <i className="icon-play"></i>
                      </button>
                    )}
                    {props.Image?.url && (
                      <img
                        src={props.Image.url}
                        alt={props.Menu_Title + "DNG Estate Agents"}
                      />
                    )}
                  </picture>
                </div>
              </div>
              {props.Video_Url && (
                <PlayVideo
                  isOpen={isPlay}
                  stopPlay={setIsPlay}
                  videoId={props.Video_Url}
                  isAutoPlay={1}
                />
              )}

              {/* {props?.People_List.map((item, index) => (
                <>
                  <Col md="6" lg="4">
                    <div className="advantage-item">
                      <picture>
                        {item.team?.Video_Url && (
                          <div className="btn-wrapper">
                            <button
                              type="button"
                              onClick={() => playVideoHandler(index)}
                            >
                              <i className="icon-play"></i>
                            </button>
                          </div>
                        )}
                        <Link
                          to={`/contact-dng-estate-agents/find-a-dng-team-member/${item.team?.URL}`}
                        >
                          <img
                            src={item.team?.Image?.url}
                            alt={
                              item.team?.Image?.alternativeText
                                ? item.team?.Image?.alternativeText +
                                  " - DNG Estate Agents"
                                : item.team?.Name + " - DNG Estate Agents"
                            }
                          />
                        </Link>
                      </picture>
                      <div className="advantage-info">
                        <Link
                          to={`/contact-dng-estate-agents/find-a-dng-team-member/${item.team?.URL}`}
                        >
                          <h3 className="team-member-name">
                            {item.team?.Name}
                          </h3>

                          <p>{item.team?.Text}</p>
                        </Link>
                      </div>
                    </div>
                    {index === isIndex && (
                      <PlayVideo
                        isOpen={isPlay}
                        stopPlay={setIsPlay}
                        videoId={item.team?.Video_Url}
                        isAutoPlay={1}
                      />
                    )}
                  </Col>
                </>
              ))} */}
            </Row>
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default PeopleVideoList
