import { Link } from "gatsby"
import React, { useState, useEffect } from "react"
import PeopleImage1 from "../../../images/person-img.jpg"
import PeopleImage2 from "../../../images/person-img1.jpg"
import ReactMarkdown from "react-markdown"
import PlayVideo from "../../PlayVideo/PlayVideo"
import ScrollAnimation from "react-animate-on-scroll"
import "./OfficeList.scss"
import { Container, Row, Col } from "react-bootstrap"
import { isMobileOnly, isTablet, isIPad13, isIOS13 } from "react-device-detect"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import Modal from "react-bootstrap/Modal"
import OfficeForm from "@Components/forms/officecontact"
import {
  GoogleMap,
  useJsApiLoader,
  LoadScript,
  InfoWindow,
  Marker,
} from "@react-google-maps/api"
import NoImage from "../../../images/no-image.png"
import marker_icon from "../../../../static/images/marker.png"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"

const MotionCol = motion(Col)
const OfficeList = props => {
  const containerStyle = {
    width: "100%",
    height: "640px",
  }

  const findPosition = props.offices?.find(office => office.Latitude !== "")

  const center = {
    lat: parseInt(findPosition?.Latitude),
    lng: parseInt(findPosition?.Longitude),
  }

  const [map, setMap] = React.useState(null)
  const [streetView, setStreetView] = React.useState(props.streetView)

  const [showingInfoWindow, setShowingInfoWindow] = React.useState(false)
  const [activeMarker, setActiveMarker] = React.useState({})
  const [selectedPlace, setSelectedPlace] = React.useState({})
  const [currentInfo, setCurrentInfo] = React.useState("")

  useEffect(() => {
    setStreetView(props.streetView)
  }, [props.streetView])

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null)
  }, [])

  const onMarkerClick = (infoId, e) => {
    setShowingInfoWindow(true)
    if (infoId === currentInfo) {
      setCurrentInfo("")
    } else {
      setCurrentInfo(infoId)
    }
  }

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    // googleMapsApiKey: "",
    googleMapsApiKey: process.env.GATSBY_MAPS_API_KEY,
  })
  const [show, setShow] = useState(false)
  const [index, setIndex] = useState(null)
  const handleClose = () => setShow(false)
  const handleShow = event => {
    setIndex(event)
    setShow(true)
  }
  const [showmark, setShowmark] = useState(false)
  const handleClosemark = () => setShowmark(false)
  const handleShowmark = event => {
    setShowmark(true)
  }
  const People = []

  let [reviewList, setCustomerReview] = useState([])
  let intialNumberOfReview = 8

  useEffect(() => {
    if (isMobileOnly) {
      intialNumberOfReview = 4
    } else if (isTablet || isIPad13 || isIOS13) {
      intialNumberOfReview = 4
    }
  }, [])

  useEffect(() => {
    setCustomerReview(People.slice(0, intialNumberOfReview))
  }, [])

  const [isPlay, setIsPlay] = useState(false)

  const playVideoHandler = () => {
    setIsPlay(true)
  }

  const postsPerPage = 16
  let arrayForHoldingPosts = []
  // const [] = useState(0);
  const [postsToShow, setPostsToShow] = useState([])
  const [next, setNext] = useState(8)
  const loopWithSlice = (start, end) => {
    // const sortedOffice = props.offices?.sort((x, y) =>
    //   x.Office_Name.localeCompare(y.Office_Name)
    // )
    const slicedPosts = props.offices?.slice(0, end)
    arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts]
    setPostsToShow(arrayForHoldingPosts)
  }

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage)
    setNext(next + postsPerPage)
  }

  useEffect(() => {
    loopWithSlice(0, postsPerPage)
    setNext(16)
  }, [props.offices, props.isAscending, props.location])

  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="office.Thumbnail.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }


  return (
    <React.Fragment>
      {props.view === "list" ? (
        <div className="office-List-block">
          <Container>
            <Row className="row-padding">
              {postsToShow?.length > 0 ? (
                postsToShow?.map((item, i) => {
                  let processedImages = JSON.stringify({})
                  if (item?.imagetransforms?.Thumbnail_Transforms) {
                    processedImages = item.imagetransforms.Thumbnail_Transforms
                  }

                  var tail_image = item.Thumbnail
                    ? item.Thumbnail
                    : item.Banner_Image
                    
                  return (
                    <InView {...inViewOptions}>
                      {({ ref, inView }) => (
                        <MotionCol
                          ref={ref}
                          md="6"
                          lg="4"
                          xl="3"
                          key={`${
                            props.isAscending ? "asc-" : "desc-"
                          }${index}`}
                          custom={index}
                          initial="hidden"
                          animate={inView ? "visible" : "hidden"}
                          variants={contentItem}
                        >
                          <div className="office-block">
                            <div className="person-img">
                              <Link
                                to={item.URL + "/ "}
                                className="img-zoom img-wrap"
                              >
                                {tail_image?.url ? (
                                  <ImageRender
                                    alt={`${item.Office_Name} ${item.Branch_Location} - DNG Estate Agents`}
                                    processedImages={processedImages}
                                    image_url={tail_image?.url}
                                    node={item}
                                  />
                                ) : (
                                  <img
                                    src={NoImage}
                                    alt={tail_image?.alternativeText}
                                  />
                                )}
                                {/* <img src={item.Banner_Image?.url ? item.Banner_Image?.url : NoImage} alt={item.Banner_Image?.alternativeText} /> */}
                                {/* {item.videoId && <button type="button" className="play-btn"  onClick={playVideoHandler}>
										<i className="icon-play"></i>
									</button>} */}
                              </Link>
                            </div>
                            <div className="person-info">
                              <span className="person-name">
                                <Link to={item.URL + "/ "}>
                                  {item.Office_Name}, {item.County}
                                </Link>
                              </span>
                              <span className="branch-name d-xl-block">
                                <ReactMarkdown
                                  source={item.Address?.replace(/,/g, ",<br/>")}
                                  allowDangerousHtml
                                />
                              </span>
                              {item.Email && (
                                <a
                                  href="javascript:;"
                                  onClick={() => handleShow(i)}
                                  className="email-text d-block d-xl-none"
                                >
                                  {item.Email}
                                </a>
                              )}
                              <div className="person-contact">
                                {item.Contact_No && (
                                  <Link
                                    href={"tel:" + item.Contact_No}
                                    className="phone-number"
                                  >
                                    <i className="icon-phone"></i>{" "}
                                    {item.Contact_No}
                                  </Link>
                                )}
                                {item.Email && (
                                  <a
                                    href="javascript:;"
                                    onClick={() => handleShow(i)}
                                    className="mail-text d-none d-xl-flex"
                                  >
                                    <i className="icon-mail"></i>
                                  </a>
                                )}
                                {i === index && (
                                  <Modal
                                    show={show}
                                    centered={true}
                                    onHide={handleClose}
                                    backdrop="static"
                                    keyboard={false}
                                    dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                  >
                                    <Modal.Header
                                      closeButton
                                      className="contact-close-btn"
                                    >
                                      <Modal.Title className="w-100">
                                        Email to {item.Office_Name},{" "}
                                        {item.County}
                                      </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body className="form">
                                      <OfficeForm
                                        toemail={item.Email}
                                        branchPhoneNum={item.Contact_No}
                                        officeName={item.Office_Name}
                                        branchLink={item.URL}
                                        off_address={item.Address}
                                      />
                                    </Modal.Body>
                                  </Modal>
                                )}
                                <Link
                                  to={item.URL + "/ "}
                                  className="arrow-symbol"
                                >
                                  details<i className="icon-arrow"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                          {/* <PlayVideo
							isOpen={isPlay}
							stopPlay={setIsPlay}
							videoId={item.videoId}
							isAutoPlay={1}
						/> */}
                        </MotionCol>
                      )}
                    </InView>
                  )
                })
              ) : (
                <Col md="12">
                  <div>No results Found</div>
                </Col>
              )}
            </Row>
          </Container>
          {postsToShow?.length !== props.offices?.length && (
            <Container>
              <Row>
                <Col md="12">
                  <div className="review-load">
                    <button
                      className="btn-pagination btn btn-primary"
                      onClick={handleShowMorePosts}
                    >
                      Load more
                    </button>
                  </div>
                </Col>
              </Row>
            </Container>
          )}
        </div>
      ) : props.view === "map" ? (
        <div className="map-view">
          {isLoaded ? (
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={8}
              onUnmount={onUnmount}
              options={{
                mapTypeControl: false,
                streetViewControl: false,
              }}
            >
              {props.offices?.map(
                (marker, i) =>
                  marker.Latitude &&
                  marker.Longitude && (
                    <Marker
                      position={{
                        lat: parseFloat(marker.Latitude),
                        lng: parseFloat(marker.Longitude),
                      }}
                      key={i}
                      icon={marker_icon}
                      onClick={e => onMarkerClick(i, e)}
                    >
                      {i === currentInfo && (
                        <InfoWindow>
                          <div className="office-block">
                            <div className="person-img">
                              <Link
                                to={marker.URL}
                                className="img-zoom img-wrap"
                              >
                                <img
                                  src={
                                    marker.Banner_Image?.url
                                      ? marker.Banner_Image?.url
                                      : NoImage
                                  }
                                  alt={marker.Banner_Image?.alternativeText?marker.Banner_Image?.alternativeText:marker?.Branch_Location+"DNG Estate Agents"}
                                />
                                {/* {item.videoId && <button type="button" className="play-btn"  onClick={playVideoHandler}>
										<i className="icon-play"></i>
									</button>} */}
                              </Link>
                            </div>
                            <div className="person-info">
                              <span className="person-name">
                                {marker.Office_Name}, {marker.County}
                              </span>
                              <span className="branch-name d-xl-block">
                                {marker.Address}
                              </span>
                              {marker.Email && (
                                <a
                                  href="javascript:;"
                                  onClick={() => handleShowmark(i)}
                                  className="email-text d-block d-xl-none"
                                >
                                  {marker.Email}
                                </a>
                              )}
                              <div className="person-contact">
                                {marker.Contact_No && (
                                  <Link
                                    href={"tel:" + marker.Contact_No}
                                    className="phone-number"
                                  >
                                    <i className="icon-phone"></i>{" "}
                                    {marker.Contact_No}
                                  </Link>
                                )}
                                {marker.Email && (
                                  <a
                                    href="javascript:;"
                                    onClick={() => handleShowmark()}
                                    className="mail-text d-none d-xl-flex"
                                  >
                                    <i className="icon-mail"></i>
                                  </a>
                                )}
                                <Modal
                                  show={showmark}
                                  centered={true}
                                  onHide={handleClosemark}
                                  backdrop="static"
                                  keyboard={false}
                                  dialogClassName="modal-90w modal-form modal-form-buy-sell-rent reviewPopup"
                                >
                                  <Modal.Header
                                    closeButton
                                    className="contact-close-btn"
                                  >
                                    <Modal.Title className="w-100">
                                      Email to {marker.Branch_Location},{" "}
                                      {marker.County}
                                    </Modal.Title>
                                  </Modal.Header>
                                  <Modal.Body className="form">
                                    <OfficeForm
                                      toemail={marker.Email}
                                      branchPhoneNum={marker.Contact_No}
                                      officeName={marker.Office_Name}
                                      branchLink={marker.URL}
                                      off_address={marker.Address}
                                    />
                                  </Modal.Body>
                                </Modal>
                                <Link to={marker.URL} className="arrow-symbol">
                                  details<i className="icon-arrow"></i>
                                </Link>
                              </div>
                            </div>
                          </div>
                        </InfoWindow>
                      )}
                    </Marker>
                  )
              )}
            </GoogleMap>
          ) : null}
        </div>
      ) : null}
    </React.Fragment>
  )
}

export default OfficeList
