import React, { useRef, useState, useEffect } from "react"
/**
 * Components
 */
import { Link } from "gatsby"
// import axios from "axios"
import Banner from "@Components/Home/Banner/Banner"
import SubscribeForm from "@Components/StaticPageTwo/SubscribeForm/SubscribeForm"
import Cta from "@Components/Home/Cta/Cta"
import About from "@Components/Home/About/About"
import AboutGrid from "@Components/Home/About/AboutGrid"
import Opinion from "@Components/Home/Opinion/Opinion"
import OfficeLocation from "@Components/Home/OfficeLocation/OfficeLocation"
import { useWindowSize } from "../hooks/window-size"
import { isMacOs, isSafari } from "react-device-detect"

import { Container, Row, Col } from "react-bootstrap"
import { useLocation } from "@reach/router"

import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import { navigate } from "@reach/router"
import ReviewsList from "@Components/Static/Reviews/reviews-listing"
import LicenseeForm from "./Static/Licensee/LicenseeForm"
import {
  ScrollingProvider,
  Section,
  useScrollSection,
  useScrollSections,
} from "react-scroll-section"
import { Valuation_Page_Url } from "@Components/common/site/constants"
import StaticSitePage from "./Static/TextContents/StaticSitePage"
import TrendingProperties from "@Components/PropertyDetails/TrendingProperties/trendingProperties"

import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import VideoTestimonials from "./Home/VideoTestimonial/VideoTestimonials"
import RentalyieldCalc from "./calculators/rentalyieldcalc"
import PlayVideo from "./PlayVideo/PlayVideo"
import { useStaticQuery, graphql } from "gatsby"
const MotionCol = motion(Col)
const MotionRow = motion(Row)
function getUnique(array, key) {
  if (typeof key !== "function") {
    const property = key
    key = function (item) {
      return item[property]
    }
  }
  return Array.from(
    array
      .reduce(function (map, item) {
        const k = key(item)
        if (!map.has(k)) map.set(k, item)
        return map
      }, new Map())
      .values()
  )
}

const Modules = props => {
  const data = useStaticQuery(graphql`
  query {
    allAreas {
      edges {
        node {
          AreaName
        }
      }
    }
   
    glstrapi {
      departments(publicationState: LIVE, sort: "Name:ASC") {
        Name
      }
      offices(
        sort: "Office_Name:ASC"
        publicationState: LIVE
        where: { Publish: true }
      ) {
        id
        imagetransforms
        Branch_Location
        Office_Name
        Address
        Thumbnail {
          alternativeText
          url
        }
        Banner_Image {
          alternativeText
          url
        }
        choose_areas {
          AreaName
        }
        City
        Contact_No
        County
        Email
        Head_Office_Branch_Location
        IsHeadOffice
        Longitude
        Latitude
        Publish
        URL
        Sort
      }
    }
    
  }
`)
  const { state, pathname } = useLocation()
  const [offices, setoffices] = useState(data.glstrapi?.offices)
  const [areas, setareas] = useState(data?.allAreas?.edges)
  const [officeslist, setofficeslist] = useState(data.glstrapi?.offices)
  const [windowWidth] = useWindowSize()
  const myRef = useRef(null)
  const executeScroll = () => {
    myRef.current.scrollIntoView({ behavior: "smooth" })
  }
  const myRefjoin = useRef(null)
  const executeScrolljoin = () => {
    myRefjoin.current.scrollIntoView({ behavior: "smooth" })
  }
  const [searchvalue, setsearchvalue] = useState("")
  const [searchvalueTeam, setsearchvalueTeam] = useState("")

  useEffect(() => {

    // getPropertyData()
    //console.log("searchvalue 2", sessionStorage.getItem("searchvalue"))

    const homePagePath = typeof window !== undefined && window.location.pathname;

    setsearchvalue(
      sessionStorage.getItem("searchvalue") !== null && homePagePath !== "/" && homePagePath !== "/why-dng/" && homePagePath !== "/about-dng-estate-agents/become-a-franchisee/" &&
        homePagePath !== "/services/become-a-franchisee/why-choose-dng-franchise/" && homePagePath !== "/services/valuations/why-dng-valuations/" && homePagePath !== "/services/valuations/"
        ? sessionStorage.getItem("searchvalue")
        : ""
    )
    if (sessionStorage.getItem("searchvalue") !== null) {
      // var arr = [];
      // data.glstrapi?.offices.filter(list => list.choose_areas?.map(c => c.AreaName?.toLowerCase()).includes(sessionStorage.getItem("searchvalue"))).map((item, index) => {
      //   arr.push(item)
      // })

      var office_list = data.glstrapi?.offices.filter(
        list =>
          list.Office_Name?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.County?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Branch_Location?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Address?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.choose_areas?.some(c =>
            c.AreaName?.toLowerCase().includes(
              sessionStorage.getItem("searchvalue")
            )
          )
      )

      setofficeslist(office_list)
      // sessionStorage.removeItem("searchvalue")
    } else {
      setofficeslist(data.glstrapi?.offices)
      sort(data.glstrapi?.offices)
      setareas(data?.allAreas?.edges)
      // console.log(data.glstrapi?.offices)
    }
    if (data.glstrapi?.offices) {
      setcountylist(getUnique(data.glstrapi?.offices, "County"))
    }
    if (data?.allTeams?.edges) {
      setbranchlist(getUnique(data?.allTeams?.edges, "Offices"))
    }
  }, [])
  // if(url !== '/contact/branch-finder'){
  //     sessionStorage.removeItem('searchvalue')
  // }

  const [countylist, setcountylist] = useState([])
  const [branchlist, setbranchlist] = useState([])

  const [showlist, setshowlist] = useState(false)
  const seearchonClink = () => {
    setshowlist(true)
  }
  const onleavesearch = e => {
    if (!searchvalue) {
      setshowlist(false)
    }
  }

  const seearchonLeave = () => {
    setshowlist(false)
  }
  const seearchonClose = () => {
    setshowlist(false)
    // setsearchEnterOn(false)
    setsearchvalue("")
    sessionStorage.removeItem("searchvalue")
    setofficeslist(data.glstrapi?.offices)
    setareas(data?.allAreas?.edges)
    if (isAscending) {
      sort(data.glstrapi?.offices)
    } else {
      desort(data.glstrapi?.offices)
    }
  }
  const sort = e => {
    const sortval = e.sort((a, b) => a.Office_Name.localeCompare(b.Office_Name))
    setofficeslist(sortval)
    setAscending(true)
  }
  // const istsort = (e) => {
  //   const sortval = e.sort((a, b) =>
  //     a.Branch_Location.localeCompare(b.Branch_Location)
  //   )
  //   setofficeslist(sortval)
  //   setAscending(true)
  // }
  const [isAscending, setAscending] = useState(true)
  const desort = e => {
    const sortval = e.sort((a, b) => b.Office_Name.localeCompare(a.Office_Name))

    setofficeslist(sortval)
    setAscending(false)
  }
  const seearchonSubmit = e => {
    e.preventDefault()
    setshowlist(false)
    window.scrollTo({
      top: 200,
      left: 0,
      behavior: "smooth",
    })
    // console.log(offices.filter(list => list.choose_areas?.map(c => c.AreaName?.toLowerCase()).includes(sessionStorage.getItem("searchvalue"))))
    //console.log("searchvalue", sessionStorage.getItem("searchvalue"))
    if (
      sessionStorage.getItem("searchvalue") !== null &&
      sessionStorage.getItem("searchvalue") !== ""
    ) {
      var office_list = data.glstrapi?.offices.filter(
        list =>
          list.Office_Name?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.County?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Branch_Location?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.Address?.toLowerCase().includes(
            sessionStorage.getItem("searchvalue")
          ) ||
          list.choose_areas?.some(c =>
            c.AreaName?.toLowerCase().includes(
              sessionStorage.getItem("searchvalue")
            )
          )
      )

      // if(office_list.length === 0){
      //   office_list = data.glstrapi?.offices.filter(list => (list.Office_Name?.toLowerCase()).includes(sessionStorage.getItem("searchvalue")));

      // }

      setofficeslist(office_list)
      if (props.Page?.Pagename === "Home") {
        navigate("/contact-dng-estate-agents/branch-finder")
      } else if (props?.Page?.Pagename === "Why DNG") {
        navigate("/contact-dng-estate-agents/branch-finder")
      } else if (props?.Page?.Pagename === "Become a Franchisee") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else if (props?.Page?.Pagename === "Why Choose DNG Franchise") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else if (props?.Page?.Pagename === "Why DNG Valuations") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else if (props?.Page?.Pagename === "Valuations") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      else {
      }
    } else {
      if (props.Page?.Pagename === "Home") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if (props?.Page?.Pagename === "Why DNG") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if (props?.Page?.Pagename === "Become a Franchisee") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if (props?.Page?.Pagename === "Why Choose DNG Franchise") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if (props?.Page?.Pagename === "Why DNG Valuations") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }
      if (props?.Page?.Pagename === "Valuations") {
        navigate("/contact-dng-estate-agents/branch-finder")
      }


      // window.scrollTo(0, 0)

      setofficeslist(data.glstrapi?.offices)

    }
    // setBranch(searchvalue)
  }



  const [isPlay, setIsPlay] = useState(false)


 
  const seearchonChange = e => {
    setsearchvalue(e.target.value.toLowerCase())
    sessionStorage.setItem("searchvalue", e.target.value.toLowerCase())
    setareas(
      data?.allAreas?.edges?.filter(list =>
        list?.node?.AreaName.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      )
    )
  }
  // const getPropertyData = async () => {
  //   const areaslistconst = await axios
  //     .get(`${process.env.GATSBY_STRAPI_SRC}/areas?_limit=-1`)
  //     .then(result => {
  //       if (result.data) {
  //         console.log(result.data)
  //       }
  //     })
  //     .catch(err => {

  //     })
  // }


  const playVideoHandler = () => {
    setIsPlay(true)
  }

  return (
    <div className={`${isSafari || isMacOs ? "mac-saf" : ""}`}>
      <ScrollingProvider scrollBehavior="smooth">

        {props.Page?.Banner_Type === "Full_Height" ? (
          <Banner
            Banner_Image={props.Page?.Banner_Image}
            Banner_Content={props.Page?.Banner_Content}
            Banner_Title={props.Page?.Banner_Title}
            Banner_Type={props.Page?.Banner_Type}
            Show_Form={props.Page?.Show_Form}
            Show_Review={props.Page?.Show_Review}
            Pagename={props.Page?.Pagename}
            Cta_Links={props.Page?.Cta_Links}
          />
        ) : null}
        {props.Modules && props.Modules?.length > 0
          ? props.Modules.map((Module, i) => {
            return (
              <div key={i}>
                {Module.__typename ===
                  "GLSTRAPI_ComponentComponentsGetStarted" && (
                    <Cta
                      Title={Module.Title}
                      Content={Module.Content}
                      Design_Title={Module.Design_Title}
                      Get_Started_Cta_Links={Module.Get_Started_Cta_Links}
                    />
                  )}


                {Module.__typename ===
                  "GLSTRAPI_ComponentComponentsAboutBlock" && (
                    <About
                      Content={Module.Content}
                      Title={Module.Title}
                      Video_Url={Module.Video_Url}
                      Tails={Module.Tails}
                      Image={Module.Image}
                      Tail_Type={Module.Tail_Type}
                      Align_type={Module.Align_type}
                      Background_Color={Module.Background_Color}
                      Choose_Team={Module.choose_teams}
                      PageName={props.Page?.Pagename}
                    />
                  )}
                {Module.__typename ===
                  "GLSTRAPI_ComponentComponentsGridBlock" && (
                    <Section id={Module.Menu_Title}>
                      <AboutGrid
                        Content={Module.Content}
                        Video_Url={Module.Video_Url}
                        Image={Module.Image}
                        Tail_Type={Module.Tail_Type}
                        Align_Type={Module.Align_Type}
                        Menu_Title={Module.Menu_Title}
                      />
                    </Section>
                  )}

                {Module.Choose_Collection === "News" && (
                  <>
                    <Opinion
                      Choose_Collection={Module.Choose_Collection}
                      Content={Module.Content}
                      executeScrolljoin={executeScrolljoin}
                      Title={Module.Title}
                      isNewsPage={
                        props.Page?.Pagename === "News & Insight"
                          ? true
                          : false
                      }
                    />
                    {/* {props.Page?.Pagename === "News & Insight" && (
                      <>
                        <div ref={myRefjoin}></div>
                        <SubscribeForm type="News" />
                      </>
                    )} */}
                  </>
                )}

                {Module.Choose_Collection === "Nationwide" && (

                  <Section id="Our Network">
                    <OfficeLocation
                      Choose_Collection={Module.Choose_Collection}
                      Content={Module.Content}
                      Title={Module.Title}
                      offices={
                        searchvalue
                          ? // offices.filter(
                          //   list =>
                          //     list.Branch_Location.toLowerCase().includes(
                          //       searchvalue
                          //     ) ||
                          //     list.County.toLowerCase().includes(searchvalue)
                          // )
                          data.glstrapi?.offices.filter(list =>
                            list.choose_areas?.some(c =>
                              c.AreaName?.toLowerCase().includes(
                                sessionStorage.getItem("searchvalue")
                              )
                            )
                          )
                          : offices
                      }
                      areas={areas}
                      setoffices={setoffices}
                      showlist={showlist}
                      seearchonChange={seearchonChange}
                      seearchonClink={seearchonClink}
                      onleavesearch={onleavesearch}
                      seearchonClose={seearchonClose}
                      seearchonLeave={seearchonLeave}
                      seearchonSubmit={seearchonSubmit}
                      searchvalue={searchvalue}
                      setsearchvalue={setsearchvalue}
                      setshowlist={setshowlist}
                    />
                  </Section>
                )}
                {/* {Module.Choose_Collection === "Newsletter" && (
                    <Register
                      Choose_Collection={Module.Choose_Collection}
                      Content={Module.Content}
                      Title={Module.Title}
                    />
                  )} */}
              </div>
            )
          })
          : ""}
        {props.Page?.Pagename === "Home" && <TrendingProperties limit={10} />}
      </ScrollingProvider>
    </div>
  )
}
export default Modules
