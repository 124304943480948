import { Link } from "gatsby"
import React, { Fragment, useState } from "react"
import opinionImage1 from "../../../../images/opinion-1.jpg"
import opinionImage2 from "../../../../images/opinion-2.jpg"
import NoImage from "../../../../images/no-image.png"
import opinionImage3 from "../../../../images/opinion-3.jpg"
import opinionImage4 from "../../../../images/opinion-4.jpg"
import PlayVideo from "../../../PlayVideo/PlayVideo"
import ImageTransform from "@Components/common/ggfx-client/module/components/image-transform"
import ScrollAnimation from "react-animate-on-scroll"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  fadeInFromLeft,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
import ReactMarkdown from "react-markdown"
import moment from "moment"
const opinionData = [
  {
    id: 1,
    image: opinionImage1,
    videoId: "V8ksSGxo6no",
    name: "Residential",
    description: "Where would you buy your ideal starter home in South Dublin",
  },
  {
    id: 2,
    image: opinionImage2,
    videoId: "",
    name: "Auctions",
    description: "DNG Market Review South West Region 2019 & Outlook 2020",
  },
  {
    id: 3,
    image: opinionImage3,
    videoId: "",
    name: "Residential",
    description: "Where would your ideal starter home be in West Dublin",
  },
  {
    id: 4,
    image: opinionImage4,
    videoId: "V8ksSGxo6no",
    name: "DNG",
    description:
      "Introducing our new Head of Lettings Management Andrew Symonds",
  },
]

const OpinionListGridCommunity = props => {
  const [opinionList, setOpinionList] = useState(opinionData)
  const [isPlay, setIsPlay] = useState(false)
  const [video_url, setVideoURL] = useState(-1)

  const playVideoHandler = video_url => {
    setIsPlay(true)
    setVideoURL(video_url)
  }
  const ImageRender = ({ alt, image_url, node, processedImages }) => {
    return (
      <ImageTransform
        imagesources={image_url}
        renderer="srcSet"
        imagename="community.Tail_Image.tail"
        attr={{ alt: alt, class: "" }}
        imagetransformresult={processedImages}
        id={node.id}
      />
    )
  }

  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <ul className="opinion-list grid community">
            {props.communities.map((item, index) => {
              let processedImages = JSON.stringify({})
              if (item?.imagetransforms?.Tail_Image_Transforms) {
                processedImages = item.imagetransforms.Tail_Image_Transforms
              }

              return (
                <motion.li
                  key={index}
                  custom={index}
                  variants={delayItemsFromBottom}
                  className="opinion-item"
                >
                  <div className="left-block">
                    <div className="img-zoom img-wrap">
                      <>
                        {item.Tail_Image?.url ? (
                          <ImageRender
                            alt={
                              item.Tail_Image?.alternativeText
                                ? item.Tail_Image?.alternativeText
                                : item.Title + " - DNG Estate Agents"
                            }
                            processedImages={processedImages}
                            image_url={item?.Tail_Image?.url}
                            node={item}
                          />
                        ) : (
                          <img
                            src={NoImage}
                            alt={item.Tail_Image?.alternativeText}
                          />
                        )}
                        {/* <img src={item.Tail_Image?.url} alt={item.Tail_Image?.alternativeText} /> */}
                      </>
                      {item.Video_Url && (
                        <div className="btn-wrapper">
                          <button
                            type="button"
                            onClick={() => playVideoHandler(item.Video_Url)}
                          >
                            <i className="icon-play"></i>
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="right-block">
                    <div className="name d-none d-md-block">
                      {moment(item?.Date).format("MMMM YYYY")}
                    </div>
                    <div className="detail community">{item.Title}</div>
                    <p>
                        <ReactMarkdown
                        source={item.Banner_Content}
                        allowDangerousHtml
                        ></ReactMarkdown>
                    </p>
                  </div>
                </motion.li>
              )
            })}
          </ul>
          <PlayVideo
            isOpen={isPlay}
            stopPlay={setIsPlay}
            videoId={video_url}
            isAutoPlay={1}
          />
        </motion.div>
      )}
    </InView>
  )
}

export default OpinionListGridCommunity
