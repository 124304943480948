import React, { useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import aboutImg1 from '../../../images/about-1.jpg';
import aboutImg2 from '../../../images/about-2.jpg';
import aboutImg3 from '../../../images/about-3.jpg';
import './aboutGrid.scss';
import PlayVideo from '../../PlayVideo/PlayVideo';
import ScrollAnimation from 'react-animate-on-scroll';
import AboutContent from './AboutContent/AboutContent';
import { useWindowSize } from '../../../hooks/window-size'
import ReactMarkdown from "react-markdown"
import { inViewOptions, contentItemStagger, contentItem, fadeInFromLeft, fadeInFromRight } from '@Components/utils/animations';
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
const AboutGrid = (props) => {
	const [isPlay, setIsPlay] = useState(false);
	const [windowWidth] = useWindowSize();


	const playVideoHandler = () => {
		setIsPlay(true);
	}

	const videoContent = (props.Image ?
		
			<motion.div className="video-wrapper" variants={fadeInFromRight}>
			<picture>
				<source media="(min-width: 768px)" srcSet={props.Image?.url} />
				<source media="(min-width: 1400px)" srcSet={props.Image?.url} />
				<img src={props.Image?.url} alt={"About DNG - DNG Estate Agents"} />
			</picture>
			{props.Video_Url &&
				<div className="btn-wrap">
					<button onClick={playVideoHandler}>
						<i className="icon-play"></i>
					</button>
				</div>
			}
		</motion.div> : null);

	return (
		<InView {...inViewOptions}>
			{({ ref, inView }) => (
				<motion.section
					className="about about-grid"
					ref={ref}
					initial="hidden"
					animate={inView ? "visible" : "hidden"}
					variants={contentItemStagger}
					style={{ backgroundColor: props.Background_Color === "White" ? '#ffffff' : props.Background_Color === "Grey" ? '#F7F8FA' : '#ffffff' }}
				>
					<Container>
						<Row>
							<Col sm="12">
								{windowWidth < 1200 && videoContent}
								{props?.Title && <motion.div variants={fadeInFromLeft}><ReactMarkdown source={props?.Title} allowDangerousHtml /></motion.div>}
								{/* {props?.Content && <ReactMarkdown source={props?.Content} allowDangerousHtml />} */}
								{/* {props.Tails && */}
									<div className="bottom-block">
										{/* {JSON.stringify(props.Content)} */}
										{props.Align_Type === "Left" ? <>
										<>{windowWidth > 1199 && videoContent}</>
										<AboutContent Content={props.Content} Tail_Type={props.Tail_Type}  />
										</>
										 : props.Align_Type === "Right" ?
										 <>
										 <AboutContent Content={props.Content} Tail_Type={props.Tail_Type}  />
										 <>{windowWidth > 1199 && videoContent}</> 
										 
										 </>
										 : <>
										 <>{windowWidth > 1199 && videoContent}</>
										 <AboutContent Content={props.Content} Tail_Type={props.Tail_Type}  />

										 </>}
										 
									</div>
									
								{/* } */}
							</Col>
						</Row>
					</Container>
					<PlayVideo
						isOpen={isPlay}
						videoId={props.Video_Url}
						stopPlay={setIsPlay}
					/>
				</motion.section>
			)}
		</InView>
	)
}

export default AboutGrid;
