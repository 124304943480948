import React from "react"
import { Link } from "gatsby"
import { Container, Row, Col, NavItem } from "react-bootstrap"
import ScrollAnimation from "react-animate-on-scroll"
import Image1 from "../../../images/img6.jpg"
import Slider from "react-slick"
import "./OurAdvantageServices.scss"
import ReactMarkdown from "react-markdown"
import GetURL from "@Components/common/site/get-url"
import { useWindowSize } from "../../../hooks/window-size"
import {
  inViewOptions,
  contentItemStagger,
  contentItem,
  containerVariants,
  delayItemsFromBottom,
} from "@Components/utils/animations"
import { motion } from "framer-motion"
import { InView } from "react-intersection-observer"
const OurAdvantageServices = props => {
  const MotionCol = motion(Col)
  const MotionRow = motion(Row)
  const [windowWidth] = useWindowSize()
  const AdvantageList = [
    {
      Images: Image1,
      Advantage: "Why Sell with DNG",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Branch Finder",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Guide to Selling",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
    {
      Images: Image1,
      Advantage: "Customer Reviews",
      Description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit pellen tesque ultricies.",
      MoreText: "+ find out more",
    },
  ]
  var settings = {
    dots: false,
    nav: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 3000,
        settings: {
          slidesToShow: 4,
          infinite: props?.Tail_Block?.length > 4 ? true : false,
        },
      },
      {
        breakpoint: 1500,
        settings: {
          slidesToShow: 3,
          infinite: props?.Tail_Block?.length > 3 ? true : false,
        },
      },
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 2,
          infinite: props?.Tail_Block?.length > 2 ? true : false,
        },
      },
      {
        breakpoint: 675,
        settings: {
          slidesToShow: 1,
          infinite: props?.Tail_Block?.length > 1 ? true : false,
        },
      },
    ],
  }
  return (
    <InView {...inViewOptions}>
      {({ ref, inView }) => (
        <motion.div
          className="our-advantage-block services"
          ref={ref}
          initial="hidden"
          animate={inView ? "visible" : "hidden"}
          variants={contentItemStagger}
        >
          <Container>
            <Row>
              <Col lg="12">
                {props?.Title && (
                  <motion.div variants={contentItem}>
                    <ReactMarkdown source={props?.Title} allowDangerousHtml />
                  </motion.div>
                )}
              </Col>
            </Row>
            {windowWidth < 1199 && (
              <div className="advantage-item-list">
                {/* <Slider {...settings}> */}
                {props.Tail_Block?.map((item, index) => (
                  <motion.div
                    key={index}
                    custom={index}
                    variants={delayItemsFromBottom}
                    className="advantage-item"
                  >
                    <div className="relative-cls">
                      <GetURL
                        class="tail-link-absolute"
                        alias={item.Parent_Url?.Alias}
                      />
                      {item.Icon?.url && (
                        <picture>
                          <img
                            src={item.Icon?.url}
                            alt={item.Icon?.alternativeText+ " - DNG Estate Agents"}
                          />
                        </picture>
                      )}
                    </div>
                    <div className="advantage-info">
                      <h3>
                        <GetURL
                          label={item.Parent_label}
                          alias={item.Parent_Url?.Alias}
                        />
                      </h3>
                      <ul>
                        {item.Sub_Links.map((list, index) => (
                          <li>
                            <GetURL
                              label={list.Label}
                              alias={list.Url?.Alias}
                            />
                          </li>
                        ))}
                      </ul>
                      {/* {item?.Content && <ReactMarkdown source={item?.Content} allowDangerousHtml />}
												<GetURL label={item.Explore_Cta?.Label} alias={item.Explore_Cta?.Url?.Alias} /> */}
                    </div>
                  </motion.div>
                ))}
                {/* </Slider> */}
              </div>
            )}
            {windowWidth > 1199 && (
              <Row>
                {props.Tail_Block?.map((item, index) => (
                  <MotionCol
                    key={index}
                    custom={index}
                    variants={contentItem}
                    md="6"
                    xl="3"
                    className="d-flex mb-space our-explore"
                  >
                    <div className="advantage-item">
                      <div className="relative-cls">
                        <GetURL
                          class="tail-link-absolute"
                          alias={item.Parent_Url?.Alias}
                        />
                        {item.Icon?.url && (
                          <picture>
                            <img
                              src={item.Icon?.url}
                              alt={"Icon - DNG Estate Agents"}
                            />
                          </picture>
                        )}
                      </div>
                      <div className="advantage-info">
                        <h3>
                          <GetURL
                            label={item.Parent_label}
                            alias={item.Parent_Url?.Alias}
                          />
                        </h3>
                        <ul>
                          {item.Sub_Links.map((list, index) => (
                            <li>
                              <GetURL
                                label={list.Label}
                                alias={list.Url?.Alias}
                              />
                            </li>
                          ))}
                        </ul>
                        {/* {item?.Content && <ReactMarkdown source={item?.Content} allowDangerousHtml />} */}
                        {/* <GetURL label={item.Explore_Cta?.Label} alias={item.Explore_Cta?.Url?.Alias} /> */}
                      </div>
                    </div>
                  </MotionCol>
                ))}
              </Row>
            )}
          </Container>
        </motion.div>
      )}
    </InView>
  )
}

export default OurAdvantageServices
